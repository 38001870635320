import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
import Head from "../components/head"
import { Link } from "gatsby"
// import LazyLoad from 'react-lazyload';
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

const Collection = ({ data }) => {

  const formatPrice = (amount) => {
    let price = (amount / 1).toFixed(2)
    let numberFormat = new Intl.NumberFormat(["en-US"], {
      style: "currency",
      currency: "USD",
      currencyDisplay: "symbol",
    })
    return numberFormat.format(price)
  }

  const collection = data.shopifyCollection
  // const { collection, productCount } = pageContext

  return (
    // <div>
    //   <h2>{collection.title}</h2>
    //   <h3>{collection.description}</h3>
    //   <img src={collection.image.src} alt={collection.title} />

    //   {collection.products.map(product => (
    //     <div key={product.shopifyId} className="col-md-4">
    //       <div className="card card-product-grid">
    //         <div className="img-wrap">
    //           <img src={product.images[0].originalSrc} alt={product.handle} />
    //         </div>
    //         <a href="#" className="title">
    //           {product.title}
    //         </a>
    //         <div className="price-wrap mt-2">
    //           <span className="price">
    //             ${product.priceRangeV2.minVariantPrice.amount}
    //           </span>
    //         </div>
    //       </div>
    //     </div>
    //   ))}
    // </div>

    <Layout>
      <Head title={collection.title} description={collection.description} />
      <Container>
        {/* <!-- section start --> */}
        <section className="section-b-space ratio_asos">
          <div className="collection-wrapper">
            <div className="container">
              <div className="row">
                <div className="collection-content col">
                  <div className="page-main-content">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="top-banner-wrapper">
                          {/* <Link to="#"> */}
                          <GatsbyImage style={{ maxWidth: 350 }}
                            // fluid={collection.image.localFile.childImageSharp.gatsbyImageData}
                            alt={collection.title}
                          />
                          {/* </Link> */}
                          {/* <Link to="#"><img src={require("../assets/images/mega-menu/2.jpg")} className="img-fluid blur-up lazyload" alt=""/></Link> */}
                          <div className="top-banner-content small-section">
                            <h4>{collection.title}</h4>
                            {/* <h5>{CatTagLine}</h5> */}
                            {/* <p> */}
                            <div
                              dangerouslySetInnerHTML={{
                                __html: collection.descriptionHtml,
                              }}
                            />
                            {/* </p> */}
                          </div>
                        </div>
                        <div className="collection-product-wrapper">
                          <div className="product-wrapper-grid">
                            <div className="row">
                              {collection.products.map(product => (
                                <div
                                  key={product.shopifyId}
                                  className="col-xl-3 col-md-6 col-grid-box"
                                >
                                  <div className="product-box">
                                    <div className="img-wrapper">
                                      <div className="front">
                                        <Link to={`/products/${product.handle}`}>
                                          <GatsbyImage image={product.images[0].gatsbyImageData}
                                            key={product.images[0].id}
                                            alt={product.handle}
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="product-detail">
                                      <div>
                                        <Link to={`/products/${product.handle}`}>
                                          <h4>{product.title}</h4>
                                          <h6>
                                            {formatPrice(`${product.priceRangeV2.maxVariantPrice.amount}`)}
                                            {/* 
                                            $
                                            {
                                              product.priceRangeV2.minVariantPrice
                                                .amount
                                            } */}
                                          </h6>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- section End --> */}
      </Container>
    </Layout>
  );
}

export const query = graphql`query ($handle: String!) {
  shopifyCollection(handle: {eq: $handle}) {
    id
    title
    description
    descriptionHtml
    image {
      originalSrc
    }
    products {
      title
      images {
        gatsbyImageData
        originalSrc
      }
      shopifyId
      handle
      description
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
    }
  }
}
`

export default Collection
  //Deleted availableForSale after description 10/06/2021
  //Deleted 
  // image {
  //  src 
  //  localFile {
  //    childImageSharp {
  //     gatsbyImageData(width: 350, placeholder: TRACED_SVG, layout: CONSTRAINED)
  //    }
  //  }
  //}
  //after descriptionHtml { 10/06/2021

  //Deleted 
  //images {
  //  originalSrc
  //  localFile {
  //    childImageSharp {
  //      gatsbyImageData(width: 910, placeholder: TRACED_SVG, layout: CONSTRAINED)
  //    }
  //  }
  //}
  //after products {
  // title